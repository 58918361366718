 
.Columnmaker {
     border: 1px;
     
    border-style: solid;
    border-color: rgb(255, 255, 255);
    pointer-events: none;
    color: rgb(255, 255, 255);
    transform: rotate(45deg);
    margin-top: 3.4vh;
    transition:all 0.3s;
  }


  .txt1{
     font-family: "Gobal_Dim","Microsoft YaHei";
     line-height: 1.2em;
     color: white;
     font-weight: 100;
  }

  .txt2{
    font-family: "Gobal_","Microsoft YaHei";
    line-height: 1.2em;
    color: white;

 }
 .txt3{
  font-family: "Gobal_","Microsoft YaHei";
  line-height: 1.1em;
  color: white;
  font-weight: 50;
  text-decoration:none;
  

}
  @font-face {
    font-family: Gobal_Dim;
    src: url('../Asset/fonts/GobalD.TTF');
  }


  @font-face {
    font-family: Gobal_;
    src: url('../Asset/fonts/Gobal.TTF');
  }